import last from 'lodash/last'
import routes from '../../../routes'
import PageComponent from '../../Components/Page'
import getPageData from '../../server/getPageData'

export default function Page (props) {
  return <PageComponent {...props} />
}

export async function getStaticProps ({
  preview = false,
  previewData,
  params
}) {
  const { slug, countryCode } = params || {}
  const route = routes.routes[countryCode]
  if (last(slug) === '__webpack_hmr') return { notFound: true }

  if (!route) {
    return {
      notFound: true
    }
  }

  const pageData = await getPageData(
    route.countryCode,
    slug?.join('/') || 'home',
    {
      active: preview,
      token: previewData?.token
    })

  const page = pageData.page

  if (!page) {
    return {
      notFound: true
    }
  }

  return {
    props: {
      data: pageData,
      preview
    },
    revalidate: 60 * 5 // 60 * 60 * 24 // 1 day revalidation
  }
}

export async function getStaticPaths () {
  return { paths: [], fallback: 'blocking' }
}
